<template>
	<v-layout column>
		<v-flex shrink>
			<v-layout mt-2 px-3 row>
				<v-icon color="grey">info</v-icon>
				<v-layout column ml-3>
					<v-flex class="grey--text">{{ $t('application.assign_user_depending_on_role_info') }}</v-flex>
				</v-layout>
			</v-layout>
		</v-flex>
		<v-flex ml-5 mt-2 pa-2 xs12>
			<w-checkbox
				v-for="role in roles"
				:key="role.key"
				v-model="role.subscribe"
				:label="role.value"
				style="width: 100%"
			/>
		</v-flex>
		<v-flex py-2 sm6 shrink text-sm-right text-xs-center xs12>
			<w-btn :flat="false" :disabled="!userAccessesIsValid"  icon="fas fa-share-alt" :loading="loading" @click="assignAppToUsers()">{{ $t('application.creation.labels.assign') }}</w-btn>
		</v-flex>
	</v-layout>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'AssignAppToUserDependingOnRole',
	mixins: [ApplicationsModuleGuard],
	props: {
		value: {
			required: false,
			type: Object,
			default: null
		},
	},
	data: function() {
		return {
			loading: false,
			roles: [
				{
					key: 'admin',
					value: this.$t('application.creation.labels.admin'),
					subscribe: false
				},
				{
					key: 'collaborator',
					value: this.$t('application.creation.labels.collaborator'),
					subscribe: false
				},
				{
					key: 'guest',
					value: this.$t('application.creation.labels.guest'),
					subscribe: false
				}
			],
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		userAccessesErrorMessages: function () {
			const result = []

			if (!this.userAccessesIsValid) {
				result.push(this.$t('application.creation.rules.min_one_role'))
			}

			return result
		},
		userAccessesIsValid: function () {
			return this.roles.filter(role => role.subscribe).length > 0
		}
	},
	methods: {
		assignAppToUsers: function() {
			this.loading = true
			return this.service.createApplicationUsers(this.accountingFirmId, this.application.catalog_application_id, {
				user_roles: this.roles.filter(role => role.subscribe).map(role => role.key)
			}).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('application.users_assigned_to_app'))
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>
